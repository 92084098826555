export const EVENT = {
  SECTION_IN: 'SECTION_IN',
  SECTION_OUT: 'SECTION_OUT',
  SECTION_STAGE_IN: 'SECTION_STAGE_IN',
  SECTION_STAGE_OUT: 'SECTION_STAGE_OUT',
  SECTION_KEYFIGURES_IN: 'SECTION_KEYFIGURES_IN',
  SECTION_SEPARATOR_IN: 'SECTION_SEPARATOR_IN',
  SECTION_SEPARATOR_OUT: 'SECTION_SEPARATOR_OUT',
  SECTION_IMAGEZOOM_IN: 'SECTION_IMAGEZOOM_IN',
  SECTION_IMAGEZOOM_OUT: 'SECTION_IMAGEZOOM_OUT',
  PERMISSIONS_CHANGED: 'PERMISSIONS_CHANGED',
}
