import Vue from 'vue'
import './app.css'
import 'lazysizes'
import './polyfills'
import './helper/svg-imports'

import { BadgerAccordion, BadgerAccordionItem } from 'vue-badger-accordion'
import { SwiperSlide } from 'vue-awesome-swiper'
import SectionObserver from './lib/SectionObserver'
import AnimatedEntry from './lib/AnimatedEntry'

Vue.config.productionTip = false

Vue.component('Navigation', () => import('./components/Navigation.vue'))
Vue.component('BurgerButton', () => import('./components/BurgerButton.vue'))
Vue.component('Stage', () => import('./components/Stage.vue'))
Vue.component('Counter', () => import('./components/Counter.vue'))
Vue.component('Separator', () => import('./components/Separator.vue'))
Vue.component('ImageZoom', () => import('./components/ImageZoom.vue'))
Vue.component('Press', () => import('./components/lists/Press.vue'))
Vue.component('Focus', () => import('./components/lists/Focus.vue'))
Vue.component('TwoColWrap', () => import('./components/lists/TwoColWrap.vue'))
Vue.component('TwoColWrapTest', () => import('./components/lists/TwoColWrapTest.vue'))
Vue.component('TwoCol', () => import('./components/lists/TwoCol.vue'))
Vue.component('Location', () => import('./components/Location.vue'))
Vue.component('VideoPlayer', () => import('./components/VideoPlayer.vue'))
Vue.component('ToggleIcon', () => import('./components/ToggleIcon.vue'))
Vue.component('Disclaimer', () => import('./components/Disclaimer.vue'))
Vue.component('CookieTable', () => import('./components/CookieTable.vue'))

Vue.component('ResearchDiagrams', () => import('./components/ResearchDiagrams.vue'))
Vue.component('TrackRecord', () => import('./components/TrackRecord.vue'))
Vue.component('Slider', () => import('./components/Slider.vue'))

Vue.component('ChartGrowth', () => import('./components/charts/ChartGrowth.vue'))
Vue.component('ChartPie', () => import('./components/charts/ChartPie.vue'))

Vue.component('SlideUpDown', () => import('vue-slide-up-down'))
Vue.component('SwiperSlide', SwiperSlide)
Vue.component('BadgerAccordion', BadgerAccordion)
Vue.component('BadgerAccordionItem', BadgerAccordionItem)

const vm = new Vue({
  delimiters: ['${', '}'],
  mounted: function() {
    Vue.nextTick().then(() => {
      new AnimatedEntry() // eslint-disable-line no-new
      new SectionObserver() // eslint-disable-line no-new
    })
  }
})

vm.$mount('#qtm-root')
